import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {auth} from './firebase';
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const SignUpForm = styled.form`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #444;
  }
`;

function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Check if the user is already authenticated
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        navigate('/dashboard');
      } else if (user && !user.emailVerified){
        return (
          <AppContainer>
              <h2>Email not Verified</h2>
              <Button onClick={handleResendEmail}>Resend Verification email</Button>
          </AppContainer>
        );
      } else{
        localStorage.clear();
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const handleResendEmail = async (e) => {
    e.preventDefault();      //Send verification email
    try {
        await sendEmailVerification(auth.currentUser);
        alert("Sending Verification Email -- Please check your email and follow instructions to complete Signup")
        navigate('/');
    } catch(error){
        console.log("Error code is", error);
        return;
    }
  }

  const handleSignUp = async (e) => {
    e.preventDefault();
    //Create the user, and then send email verification.
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      //Send verification email
      try {
        await sendEmailVerification(auth.currentUser);
        setTimeout(() => navigate('/'), 3000);
        alert("Sending Verification Email -- Please check your email and follow instructions to complete Signup")
      } catch(error){
        console.log("Error code is", error);
        return;
      }
    } catch (error) {
        if(error.code == 'auth/email-already-in-use') {
          alert("We already have a user with this email. Please sign in");
          setTimeout(() => navigate('/'), 3000);
        }
        return;
    }
 }

  return (
    <AppContainer>
      <SignUpForm>
        <h2>Enter Your email</h2>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <h2>Enter A Password</h2>
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button onClick={handleSignUp}>Sign Up</Button>
      </SignUpForm>
    </AppContainer>
  );
}

export default Signup;
