import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';
import { useNavigate, Link} from 'react-router-dom';

const WishlistContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const WishlistList = styled.ul`
  list-style: none;
  padding: 0;
`;

const WishlistItem = styled.li`
  background-color: #fff;
  color: #000;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center vertically */
`;

/* General styles for the button */
const CrossButton = styled.button`
  background-color: #ff4d4d; /* Red background color */
  color: white; /* White text color */
  padding: 0.5rem;
  border: none; /* No border */
  border-radius: 50%; /* Circular button */
  width: 15px; /* Width of the button */
  height: 15px; /* Height of the button */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 12x; /* Font size for the cross */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
`;

const AddButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  width: calc(100% - 2rem);
  max-width: 300px;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const ItemCard = styled.div`
  background-color: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 2rem;
  text-align: center;
  border: none;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const Carousel = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-between;
 background-color: gray;
`;
const ChevronLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

const ChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);



function Wishlist() {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(JSON.parse(localStorage.getItem('wishlist'))|| []); //from Dashboard
  const [newItem, setNewItem] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');
  const [doUpdate, setDoUpdate] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [recoList, setrecoList] = useState(JSON.parse(localStorage.getItem('recolist')) ||[]); //from Dashboard

  //  { id: 1, title: "Card 1", content: "This is the content of card 1" },
  //  { id: 2, title: "Card 2", content: "This is the content of card 2" },
  //  { id: 3, title: "Card 3", content: "This is the content of card 3" },
  //  { id: 4, title: "Card 4", content: "This is the content of card 4" },
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const openModal = (link) => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % recoList.length);
  };

  const prevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + recoList.length) % recoList.length);
  };

  const Modal = ({ isOpen, onClose, link }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl">
          <h2 className="text-xl font-bold mb-4">Link Modal</h2>
          <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            Click here to open link
          </a>
          <button
            onClick={onClose}
            className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const user = auth.currentUser; 
  console.log("Wishlist set to :", wishlist);

  useEffect(() => {
    const updateWishList = async() => {
      try{
        console.log("Update database for wishlist from user:", user.uid);
        setWishlist(JSON.parse(localStorage.getItem('wishlist'))|| []);
        const wishDocRef =  doc(db,'wishlist', user.uid);
        setDoc(wishDocRef, {userlist: wishlist}, {merge:true});
        console.log('Updated wishlist for user:', user.uid);
      } catch (error) {
        console.error('Error updating wishlist data',error);
      }
    };
    if(doUpdate) {
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      updateWishList(); //write to firestore for this user
      
    } else {
      console.log("No do update");
    }
  }, [doUpdate]);

  const delItem = (index) => {
    const updatedList = wishlist.filter((_, i) => i!==index);
    setWishlist(updatedList);
  };

  if (user) {
    const addItem = () => {
      if (newItem.trim()) {
        setWishlist([...wishlist, {name: newItem.trim(), price: newItemPrice.trim()}]);
        setNewItem('');
        setNewItemPrice('');
        //setDoUpdate(true); 
        //console.log("Setting do update to true");
      }
    };
    return (
        <WishlistContainer>
          <Title>Wishlist</Title>
          <WishlistList>
          {wishlist.map((item, index) => (
                <WishlistItem key={index}>
                  <span>{item.name} </span>
                  <span> ${item.price}</span>
                  <CrossButton onClick={()=>delItem(index)}>X</CrossButton>
                </WishlistItem>
              ))}
          </WishlistList>
          <Input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Type item here..."
          />
          <Input
            type="number"
            value={newItemPrice}
            onChange={(e) => setNewItemPrice(e.target.value)}
            placeholder="Type item price here..."
          />
          <AddButton onClick={addItem}>+ Add an item to your wishlist</AddButton>
          <Button onClick={() => setDoUpdate(true)}>Update my Wish List</Button>
          <Link to="/dashboard">Return to Dashboard</Link>
       
       
          <Carousel>
            <div className="relative w-64 h-96">
              {recoList.map((card, index) => (
                <ItemCard onClick={openModal(card.link)}>
                  key={card.id}
                  className={`absolute top-0 left-0 w-full h-full transition-all duration-300 ease-in-out ${
                    index === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'
                  }`}
                
                  <div className="bg-white rounded-lg shadow-lg p-6 h-full flex flex-col justify-between">
                    <h2 className="text-xl font-bold mb-4">{card.title}</h2>
                    <img src={card.pic} alt="Item" />
                    <p>{card.content}</p>
                  </div>
                </ItemCard>
              ))}
            </div>
            <div className="flex justify-between w-64 mt-4">
              <button
                onClick={prevCard}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
              <ChevronLeft />
              </button>
              <button
                onClick={nextCard}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                <ChevronRight />
              </button>
            </div>
          </Carousel>
        </WishlistContainer>

      );
  } else {
    localStorage.clear();
    return (
      navigate('/')
    );
  }
      
}

export default Wishlist;
