import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {auth} from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const LoginForm = styled.form`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #444;
  }
`;

function EmailVerification() {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  
  useEffect(()=> {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // User is signed in
        setTimeout(() => navigate('/dashboard'), 3000);
      } else{
        // User is signed out
        navigate('/');
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
    }, [navigate]);

  const handleResendVerification = async () => {
    try {
      await auth.currentUser.sendEmailVerification();
      alert('Verification email re-sent. Please check your inbox.');
    } catch (error) {
      console.error('Error resending verification email:', error.message);
    }
  };

  const handleTestVerification = async () => {
    const user =auth.currentUser;
    await user.reload();
    setTimeout(() => navigate('/'), 1000);
  };

  return (
    <AppContainer>
      <h2>Email Verification</h2>
      {isVerified ? (
         <h2>Email is verified. Proceed to see your Dashboard</h2>
      ) : (
        <LoginForm>
          <Button onClick={handleTestVerification}> Email verified</Button>
          <h2>Didn't get Email?</h2>
          <Button onClick={handleResendVerification}>Resend Verification Email</Button>
        </LoginForm>
      )}
    </AppContainer>
  );
}

export default EmailVerification;
