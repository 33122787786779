import React, { useState , useEffect} from 'react';
import styled from 'styled-components';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';
import {Link, useNavigate} from 'react-router-dom';

const LogTasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const TaskList = styled.ul`
  list-style: none;
  padding: 0;
`;

const TaskItem = styled.li`
  background-color: #fff;
  color: #000;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
   align-items: center; /* Center vertically */
`;

const Total = styled.h3`
  margin-top: 2rem;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const AddButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;i
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  width: calc(100% - 2rem);
  max-width: 300px;
`;

/* General styles for the button */
const CrossButton = styled.button`
  background-color: #ff4d4d; /* Red background color */
  color: white; /* White text color */
  padding: 0.5rem;
  border: none; /* No border */
  border-radius: 50%; /* Circular button */
  width: 15px; /* Width of the button */
  height: 15px; /* Height of the button */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 12x; /* Font size for the cross */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
`;


function LogTasks() {
  const [tasks, setTasklist] = useState(JSON.parse(localStorage.getItem('tasks'))|| []); //from Dashboard
  const [total, setTotal] = useState(tasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0));
  const [newTask, setNewTask] = useState('');
  const [newTaskAmount, setNewTaskAmount] = useState(0);
  const [doUpdate, setDoUpdate] = useState(false);
  const user = auth.currentUser; 
  const navigate= useNavigate();

  useEffect(() => {
    const updateTaskList = async() => {
      try{
        console.log("Update database for wishlist from user:", user.uid);
        const wishDocRef =  doc(db,'wishlist', user.uid);
        setDoc(wishDocRef, {tasks: tasks}, {merge:true});
        setDoc(wishDocRef,{income:total}, {merge:true});
        setTimeout(() => navigate('/dashboard'), 3000);
      } catch (error) {
        console.error('Error updating tasks data',error);
      }
    };
    updateTaskList(); //write to firestore for this user
  }, [doUpdate]);

  const addTask = () => {
    if (newTask.trim()) {
      setTasklist([...tasks, {name: newTask.trim(), amount: newTaskAmount.trim()}]);
      setNewTask('');
      setNewTaskAmount('');
    }
  };

  const delTask = (index) => {
    const updatedTask = tasks.filter((_, i) => i!==index);
    setTasklist(updatedTask);
  };

  const handleDoneTaskUpdate= () => {
    //Update the localStorage
    localStorage.setItem('tasks', JSON.stringify(tasks));
    //Update the total
    setTotal(tasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0));
    localStorage.setItem('income', JSON.stringify(total));
    setDoUpdate(true); 
  };

  return (
    <LogTasksContainer>
      <Title>
        Let me guess... You found some money on trees? No worries! Log extra
        money making here!
      </Title>
      <TaskList>
        {tasks.map((task, index) => (
          <TaskItem key={index}>
            <span> {task.name}</span>
            <span>  ${task.amount}</span>
            <CrossButton onClick={()=>delTask(index)}>X</CrossButton>
          </TaskItem>
        ))}
      </TaskList>
      <Total>Total: ${total}</Total>
      <Input
            type="text"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            placeholder="Name for New Income Source"
          />
          <Input
            type="number"
            value={newTaskAmount}
            onChange={(e) => setNewTaskAmount(e.target.value)}
            placeholder="Amount earned from New Income Source"
          />
          <AddButton onClick={addTask}>+ Add income to your income list</AddButton>
          <Button onClick={handleDoneTaskUpdate}>Done Updating Income Sources</Button>
          <Link to="/dashboard">Return to Dashboard</Link>
    </LogTasksContainer>
  );
}

export default LogTasks;
