import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {auth} from './firebase';
import { updatePassword} from "firebase/auth";

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const PwdForm = styled.form`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  &:hover {
    background-color: #444;
  }
`;

function UpdatePwd() {
  const [password, setPassword] = useState(''); 
  const navigate = useNavigate();
  const user = auth.currentUser;

  const getNewPwd = async (e) => {
   e.preventDefault();
   //If user exists, then go to handleLogin
    try {
      await updatePassword(user, password);
      alert('Updating Password');
      navigate('/');
    } catch (error) {
      alert("Sorry we were unable to update your password")
    }
 }

return (
  <AppContainer>
    <PwdForm onSubmit={getNewPwd}>
      <h2>Please Enter new password</h2>
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <Button type="submit">UpdatePasword</Button>
    </PwdForm>
  </AppContainer>
);
}
export default UpdatePwd;
